<template>
  <div class="container">
    <h1 class="pb-3">Вход на страницу редактирования</h1>
    <form  class="form">
      <div class="form-group">
        <p class="text-muted">Используйте логин и пароль от терминала агента</p>
        <label for="exampleInputEmail1">Логин</label>
        <input required class="form-control" v-model="loginModalInputUsername"
        id="loginModalInputUsername" autocomplete="username"
        aria-describedby="loginHelp" placeholder="">
      </div>
      <div class="form-group">
        <label for="ModalInputDesc">Пароль</label>
        <input required type="password" autocomplete="current-password"
        v-model="loginModalInputPassword" class="form-control form-control-password"
        id="loginModalInputPassword" placeholder="">
      </div>
      <button type="submit"
      v-on:click.stop.prevent='Login(loginModalInputUsername,loginModalInputPassword)'
      class="btn btn-primary">Войти</button>
    </form>
    <br>

    <span>{{ this.current_user }}</span>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      url: 'https://weather.matveymatvey.com/emlslogin',
      // url: 'http://127.0.0.1:5000/emlslogin',
      loginModalInputUsername: '',
      loginModalInputPassword: '',
      current_user: '',
      login: '',
      password: '',
      is_auth: false,
    };
  },
  methods: {
    async Login(username, passwd) {
      this.current_user = 'Вход...';
      const creds = { login: username, password: passwd };
      await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(creds),
      })
        .then((response) => {
          if (response.status === 500) {
            this.current_user = 'Ошибка сервера API';
            throw new Error(response.status);
          } else if (response.status === 400) {
            this.current_user = 'Недостаточно данных для входа';
            throw new Error(response.status);
          } else if (response.status === 403) {
            this.current_user = 'Неправильное имя пользователя или пароль';
            throw new Error(response.status);
          } else return response.json();
        })
        .then((data) => {
          this.$cookie.set('token', data.token, { expires: '30m' });
          this.$router.push('/EditPage');
        })
        .catch((error) => { console.error('There was en error:', error); });
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form {max-width: 500px;}
</style>
