<template>
  <div class="container-fluid main">
    <transition name="fade">
      <div class="backdrop" v-if="this.loading">
        <img src="../assets/logo.png" />
        <div class="loader"></div>
      </div>
    </transition>
    <div class="container pt-3">
      <div class="heading bg-dark text-light p-3">
        <button
          type="submit"
          v-on:click.stop.prevent="LogOut()"
          class="btn btn-sm float-right d-inline btn-light"
        >
          Выход
        </button>
        <h4 class="">Рады вас видеть, {{ this.name }}</h4>
        <p>ваш id - {{ this.current_user.emls_id }}</p>
      </div>

      <div class="share d-inline-block border mt-3 p-3 bg-light">
        <h3>Поделитесь ссылкой на вашу страницу</h3>
        <p>
          Ссылка на вашу личную презентационную страницу -
          <a
            class="bolder"
            target="_new"
            :href="`https://card.advecs.com/${this.current_user.emls_id}`"
          >
            https://card.advecs.com/{{ this.current_user.emls_id }}</a
          >
          <br />
          Поделитесь ссылкой в соцсетях и мессенджерах, нажав кнопку ниже
        </p>
        <div class="buttons">
          <ShareNetwork
            class="btn text-light mr-2 mb-2"
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :style="{ backgroundColor: network.color }"
            :url="shareurl"
            :image="sharepicture"
            :title="sharetitle"
            :description="sharedescription"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork>
        </div>
      </div>

      <div class="pt-3 form animate__animated animate__fadeIn" v-if="current_user.emls_id">
        <h3>Редактирование контактной информации</h3>
        <p class="lead w-75">
          Заполните свои контактные данные. Убедитесь что вводите корректные данные, иначе с вами не
          смогут связаться. Если поле не заполнено, оно не отображается.<br /><br />
          Ваши отзывы, объекты и сертификаты автоматически загружаются с advecs.com. Фото — из
          отдела кадров.
        </p>
        <form class="form animate__animated animate__fadeIn" autocomplete="off">
          <div class="row">
            <div class="col-lg">
              <div class="socialnetworks p-3 mb-3 border bg-light">
                <h4>Соцсети</h4>
                <p class="text-muted">Вставьте ссылки на свои страницы в соцсетях</p>
                <div class="form-inline row mb-2">
                  <label for="vkontakte" class="col-4 col-form-label">Вконтакте</label>
                  <input
                    type="text"
                    v-model="current_user.vkontakte"
                    v-mask="{
                      regex: '(https:\/\/)(www\\.)?(vk\\.com)\\/[a-zA-Z0-9_.]*',
                      placeholder: '',
                      greedy: 'false'
                    }"
                    class="form-control col-lg-7 col-sm-7"
                    id="vkontakte"
                    placeholder="https://vk.com/user"
                  />
                </div>
                <div class="form-inline row mb-2">
                  <label for="youtube" class="col-4 col-form-label">Youtube</label>
                  <input
                    type="text"
                    v-model="current_user.youtube"
                    class="col-lg-7 col-sm-7 form-control"
                    id="youtube"
                    v-mask="{
                      regex: '(https:\/\/)(www\\.)?(youtube\\.com)\\/[a-zA-Z0-9_./]*',
                      placeholder: ''
                    }"
                    placeholder="https://youtube.com/user"
                  />
                </div>
                <div class="form-inline row mb-2">
                  <label for="odnoklassniki" class="col-4 col-form-label">Одноклассники</label>
                  <input
                    type="text"
                    v-model="current_user.odnoklassniki"
                    class="col-lg-7 col-sm-7 form-control"
                    v-mask="{
                      regex: '(https:\/\/)(www\\.)?(ok\\.ru)\\/[a-zA-Z0-9_./]*',
                      placeholder: ''
                    }"
                    id="odnoklassniki"
                    placeholder="https://ok.ru/user"
                  />
                </div>
              </div>

              <div class="p-3 mb-3 bg-light border">
                <h4>Мессенджеры</h4>
                <p class="text-muted">
                  Вставьте соответствующие данные для мессенджеров, которые вы используете
                </p>
                <div class="form-inline row mb-2">
                  <label for="viber" class="col-6 col-form-label">Номер телефона для Viber</label>
                  <input
                    type="text"
                    v-model="current_user.viber"
                    class="col-5 form-control"
                    id="viber"
                    v-mask="{ mask: '+7 (999) 999 99 99', autoUnmask: true }"
                    placeholder="+7 (922) 123 45 67"
                  />
                </div>
                <div class="form-inline row mb-2">
                  <label for="telegram" class="col-4 col-form-label">Ссылка на Telegram</label>
                  <input
                    type="text"
                    v-model="current_user.telegram"
                    class="col-7 form-control"
                    id="telegram"
                    v-mask="{
                      regex: '(https:\/\/)(www\\.)?(t\\.me)\\/[a-zA-Z0-9_./]*',
                      placeholder: ''
                    }"
                    placeholder="https://t.me/user"
                  />
                </div>
                <div class="form-inline row mb-2">
                  <label for="skype" class="col-4 col-form-label">Логин в Skype</label>
                  <input
                    type="text"
                    v-model="current_user.skype"
                    class="col-7 form-control"
                    id="skype"
                    placeholder="User"
                  />
                </div>
                <div class="form-inline row mb-2">
                  <label for="tamtam" class="col-4 col-form-label">Ссылка на Tamtam</label>
                  <input
                    type="text"
                    v-model="current_user.tamtam"
                    class="col-7 form-control"
                    id="tamtam"
                    v-mask="{
                      regex: '(https:\/\/)(www\\.)?(tt\\.me)\\/[a-zA-Z0-9_./]*',
                      placeholder: ''
                    }"
                    placeholder="https://tt.me/user"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg">
              <div class="form-group p-3 mb-3 bg-light border">
                <h4><label for="about">О себе</label></h4>
                <p
                  class="text-muted"
                  v-if="current_user.about"
                  v-text="'Осталось символов ' + (this.textareamax - current_user.about.length)"
                ></p>
                <p class="text-muted" v-else v-text="'Осталось символов ' + this.textareamax"></p>
                <textarea
                  rows="8"
                  type="textarea"
                  v-model="current_user.about"
                  class="form-control"
                  id="about"
                  :maxlength="this.textareamax"
                  placeholder="Введите небольшой текст о себе"
                ></textarea>
              </div>
            </div>
          </div>

          <div
            v-if="showalert"
            class="w-50 alert alert-warning alert-dismissible
fade show animate__animated animate__fadeIn"
            role="alert"
          >
            {{ this.db_result.msg }}
            <button type="button" class="close" v-on:click="showalert = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <button
            :disabled="!!btnDisable"
            type="submit"
            v-on:click.stop.prevent="sendForm()"
            class="mb-5 mt-3 btn btn-success btn-lg"
          >
            Сохранить&nbsp;
            <span
              class="align-middle spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="this.btnloading"
            ></span>
          </button>
        </form>
      </div>
    </div>

    <div class="modal fade" id="loginModal" ref="loginModal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <h4 class="pb-3">
              Истек срок действия авторизации.
              <br /><br />
              Чтобы сохранить данные, повторно введите свои логин и пароль
            </h4>
            <form class="form">
              <div class="form-group">
                <p class="text-muted">Используйте логин и пароль от терминала агента</p>
                <label for="exampleInputEmail1">Логин</label>
                <input
                  required
                  class="form-control"
                  v-model="loginModalInputUsername"
                  id="loginModalInputUsername"
                  autocomplete="username"
                  aria-describedby="loginHelp"
                  placeholder=""
                />
              </div>
              <div class="form-group">
                <label for="ModalInputDesc">Пароль</label>
                <input
                  required
                  type="password"
                  autocomplete="current-password"
                  v-model="loginModalInputPassword"
                  class="form-control form-control-password"
                  id="loginModalInputPassword"
                  placeholder=""
                />
              </div>
              <button
                type="submit"
                v-on:click.stop.prevent="sendFormModal()"
                class="btn btn-primary"
              >
                Войти
              </button>
              {{ this.login }}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
// import Inputmask from 'inputmask';

export default {
  name: "EditPage",
  data() {
    return {
      btnDisable: false,
      textareamax: 1100,
      loginModalInputUsername: "",
      loginModalInputPassword: "",
      btnloading: false,
      sharing: {
        quote: "",
        hashtags: "адвекснедвижимость,недвижимостьвспб"
      },
      networks: [
        {
          network: "vk",
          name: "Вконтакте",
          icon: "fab fah fa-lg fa-vk",
          color: "#4a76a8"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5"
        },
        {
          network: "odnoklassniki",
          name: "Одноклассники",
          icon: "fab fah fa-lg fa-odnoklassniki",
          color: "#ed812b"
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fab fah fa-lg fa-telegram-plane",
          color: "#0088cc"
        },
        {
          network: "skype",
          name: "Skype",
          icon: "fab fah fa-lg fa-skype",
          color: "#00aff0"
        },
        {
          network: "email",
          name: "Email",
          icon: "far fah fa-lg fa-envelope",
          color: "#333333"
        },
        {
          network: "viber",
          name: "Viber",
          icon: "fab fah fa-lg fa-viber",
          color: "#59267c"
        }
      ],
      current_user: "",
      db_result: "",
      login: "",
      name: "",
      showalert: false,
      loading: true,
      url: "https://weather.matveymatvey.com/emlslogin"
    };
  },
  directives: {},
  methods: {
    async GetProtected() {
      await fetch("https://weather.matveymatvey.com/protected", {
        // await fetch('http://127.0.0.1:5000/protected', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$cookie.get("token")}`
        }
      })
        .then(response => response.json())
        .then(response => {
          this.current_user = response.agent;
          this.name = response.name;
          this.loading = false;
        })
        .catch(error => {
          console.error("Error:", error);
        }, (this.current_user = "Anon"));
    },
    Login(username, passwd) {
      this.login = "Вход...";
      const creds = { login: username, password: passwd };
      fetch(this.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(creds)
      })
        .then(response => {
          if (response.status === 500) {
            this.login = "Ошибка сервера API";
            throw new Error(response.status);
          } else if (response.status === 400) {
            this.login = "Недостаточно данных для входа";
            throw new Error(response.status);
          } else if (response.status === 403) {
            this.login = "Неправильное имя пользователя или пароль";
            throw new Error(response.status);
          } else return response.json();
        })
        .then(data => {
          this.$cookie.set("token", data.token, { expires: "30m" });
          $("#loginModal").modal("hide");
          this.login = "";
          this.Addtodb();
        })
        .catch(error => {
          console.error("There was en error:", error);
        });
    },
    LogOut() {
      this.$cookie.delete("token");
      this.$router.push("/Login");
    },
    sendForm() {
      this.showalert = false;
      this.btnloading = true;
      this.btnDisable = true;
      if (!this.$cookie.get("token")) {
        $("#loginModal").modal("show");
      } else {
        this.Addtodb();
      }
    },
    sendFormModal() {
      this.Login(this.loginModalInputUsername, this.loginModalInputPassword);
    },
    Addtodb() {
      const dict = {
        id: { emls_id: this.current_user.emls_id },
        data: {
          advecscom_link: this.current_user.advecscom_link,
          telegram: this.current_user.telegram,
          vkontakte: this.current_user.vkontakte,
          facebook: this.current_user.facebook,
          instagram: this.current_user.instagram,
          youtube: this.current_user.youtube,
          odnoklassniki: this.current_user.odnoklassniki,
          about: this.current_user.about,
          viber: this.current_user.viber,
          skype: this.current_user.skype,
          tamtam: this.current_user.tamtam,
          tiktok: this.current_user.tiktok
        }
      };

      fetch(`https://weather.matveymatvey.com/edit/${this.current_user.emls_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$cookie.get("token")}`
        },
        body: JSON.stringify(dict)
      })
        .then(response => response.json())
        .then(response => {
          this.db_result = response;
          this.showalert = true;
          this.btnloading = false;
          this.btnDisable = false;
        })
        .catch(error => {
          console.error("Error:", error);
        });
    }
  },
  mounted() {
    this.GetProtected();
  },
  computed: {
    sharetitle() {
      const title = `${this.name} – агент ГК «Адвекс. Недвижимость»`;
      return title;
    },
    shareurl() {
      const url = `https://card.advecs.com/share.php?id=${this.current_user.emls_id}`;
      return url;
    },
    sharepicture() {
      // return this.current_user.photo;
      return true;
    },
    sharedescription() {
      return this.current_user.description;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backdrop {
  background-color: #e9ecef;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.main {
  padding: 0;
}
.col-form-label {
  justify-content: flex-start;
}
input {
}
textarea::placeholder,
input::placeholder {
  color: lightgrey;
}

.vk {
  background-color: #2787f5;
}

.telegram {
  background-color: #0088cc;
}

.youtube {
  background-color: #ff0000;
}
.tiktok {
  background-color: #010101;
  color: white;
}
.odnoklassniki {
  background-color: #ed812b;
}
.viber {
  background-color: #7360f2;
}
.skype {
  background-color: #00aff0;
}
.imessage {
  background-color: #2dd223;
}
.about {
  font-size: 16px !important;
  margin-top: 35px;
}
.about p {
  white-space: pre-wrap;
}
.tamtam {
  background-color: #6678f7;
}
</style>
