import 'reveal.js/dist/reveal.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'popper.js/dist/popper';
import 'animate.css/animate.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faVk, faTelegram, faYoutube, faTiktok, faOdnoklassniki,
  faViber, faSkype,
}
  from '@fortawesome/free-brands-svg-icons';
import {
  faPhone, faEnvelope, faComment, faGraduationCap, faHome, faExternalLinkAlt,
}
  from '@fortawesome/free-solid-svg-icons';
import Vue from 'vue';
import Reveal from 'reveal.js';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie';
import VueSocialSharing from 'vue-social-sharing';
import VueMeta from 'vue-meta';
import Simplemenu from './assets/simplemenu/simplemenu';

import App from './App.vue';
import FullScreen from './components/FullScreen.vue';
import FullScreensm from './components/FullScreensm.vue';
import FullScreenwoa from './components/FullScreenwoa.vue';
import ReviewsFetch from './components/Reviews_fetch.vue';
import Login from './components/Login.vue';
import EditPage from './components/EditPage.vue';
import TopGrid from './components/TopGrid.vue';

const VueInputMask = require('vue-inputmask').default;

library.add(
  faVk,
  faPhone,
  faYoutube,
  faTiktok,
  faOdnoklassniki,
  faEnvelope,
  faViber,
  faSkype,
  faTelegram,
  faComment,
  faGraduationCap,
  faHome,
  faExternalLinkAlt,
);

Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.use(VueInputMask);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = true;

const routes = [
  { path: '/gettop/:office', component: TopGrid, props: true },
  {
    path: '/Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if ((to.path === '/Login' || to.path === '/login') && (Vue.cookie.get('token'))) next({ path: '/EditPage' });
      else next();
    },
  },
  {
    path: '/EditPage',
    component: EditPage,
    meta: {
      requiresAuth: true,
    },
  },
  { path: '/:id', component: FullScreen, props: true },
  { path: '/Reviews/:id', component: ReviewsFetch, props: true },
  { path: '/', component: FullScreenwoa },
  { path: '/sm/:id', component: FullScreensm, props: true },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active', // active class for non-exact links.
  linkExactActiveClass: 'active', // active class for *exact* links.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (Vue.cookie.get('token')) {
      next();
    } else {
      next({ path: '/Login' });
    }
  }
  next();
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

if (document.documentElement.clientWidth > 500) {
  Reveal.initialize({
    controls: false,
    progress: false,
    history: true,
    center: false,
    touch: true,
    mouseWheel: true,
    disableLayout: true,
    overview: false,
    help: false,
    pause: false,
    minScale: 1,
    maxScale: 1,
    plugins: [Simplemenu],
  });
}
