<template>
  <section id="Title" data-menu-title="Title">
    <div class="container text-right">
      <div class="person">
      <div class="advecs_mobile">ГК «АДВЕКС. НЕДВИЖИМОСТЬ», САНКТ-ПЕТЕРБУРГ</div>
      <img v-if="agent.photo" :src="agent.photo" class="photo">
      <img v-else src="../assets/nophoto.jpg" class="photo">
      <div class="name">{{agent.name}}</div>
      <div class="surname text-nowrap">{{agent.surname}}</div>

      <div class="text-menu-mobile">
        <ul class="nav flex-column">
          <!-- Here's the text menu -->
          <li><a href="#Title">ГЛАВНАЯ</a></li>
          <li><a href="#Contacts">КОНТАКТЫ АГЕНТА</a></li>
          <li><a href="#Company">О КОМПАНИИ</a></li>
          <li><a href="#Services">УСЛУГИ</a></li>
          <li><a href="#Whyus">ВЫБИРАЙТЕ НАС</a></li>
          <li><a href="#Buying">ПЛАН ПОКУПКИ</a></li>
          <li><a href="#Selling">ПЛАН ПРОДАЖИ</a></li>
          <li><a href="#Partners">ПАРТНЕРЫ</a></li>
          <li><a href="#Novostr">ПОКУПКА НОВОСТРОЙКИ</a></li>
          <li><a href="#MarketingPlan">МАРКЕТИНГОВЫЙ ПЛАН</a></li>
          <li><a href="#Gic">ИПОТЕКА</a></li>
        </ul>
      </div>

      <div class="advecs" v-if="agent.name">ГК «АДВЕКС. НЕДВИЖИМОСТЬ», САНКТ-ПЕТЕРБУРГ</div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleSlide',
  props: ['agent'],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.person {
  margin-right:60px;
  /* float: right; */
}

.photo {
  /* margin-top: 20px; */
  margin-bottom: 10px;
  width: 324px;
  height: 493px;
  object-fit: cover;
}

.name {
  font-family: Gilroy-Thin;
  font-size: 4.2em;
  margin-bottom: -17px;
  display: block;
  letter-spacing: -4px;
}

.surname {
  font-family: Gilroy-Bold;
  font-size: 4.5em;
  margin-bottom: 5px;
}

.folder {
  margin-top: 20px;
  font-family: Gilroy-SemiBold;
  font-size: 2em;
  text-transform: uppercase;
  display: block;
  margin-bottom: -17px;
}

.advecs {
  font-family: Gilroy-Regular;
  font-size: 1.1em;
  letter-spacing: -0.5px;
}

.advecs_only {
  font-family: Gilroy-Regular;
  font-size: 1.1em;
  letter-spacing: -0.5px;
}

.advecs_mobile {
  display: none;
}

.text-menu-mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .container {
    padding-right: 0;
    padding-left: 0;
    margin-top: 18px;
  }
  .person {
    margin: 0;
    text-align: center;
  }
  .folder {
    display: none;
  }
  .advecs {
    display: none
  }
  .advecs_mobile {
    display: block;
    font-family: Gilroy-Regular;
    font-size: 14px;
    text-transform: uppercase;
  }
  .photo {
    margin-top: 13px;
    margin-bottom: 10px;
    width: 202px;
    height: 252px;
    object-fit: cover;
    margin-bottom: 0;
  }
  .name {
    font-size: 2em;
    display: inline;
    margin-right: 10px;
  }
  .surname {
    font-size: 2em;
    display: inline;
  }
  .text-menu-mobile {
    display: block;
    text-align: left;
  }
  .reveal .text-menu-mobile ul li a, .reveal .text-menu-mobile ul li a,
  .reveal ul.text-menu-mobile li a, .reveal ul.text-menu-mobile li a {
  color: #000000;
  font-family: Gilroy-Light;
  text-size: 20.8px;
  }
}
</style>
