<template>
  <div>
    <h3 class="mb-3">
        {{ title }}
    </h3>
    <!-- <div class="card-deck"> -->
      <a :href="item.link" target=_blank class="text-muted small" v-for="(item, index)
      in obj" :key="index">
      <div class="card mb-3">
        <img class="card-img-top" :src="item.photo" :alt="item.address" @error="setAltImg">
        <div class="card-body">
          <h6 class="card-subtitle">{{ item.address }}</h6>
          <small>{{ item.id }}</small>
        </div>
      </div>
    </a>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'Objectsdeck',
  props: ['obj', 'title'],
  data() {
    return {
      loading: false,
      list_error: '',
    };
  },
  methods: {
    setAltImg(e) {
      e.target.src = '../assets/flatimg.jpg';
    },
  },
};
</script>

<style scoped>
body {
  background-image: unset;
  background-color: lightgrey;
  font-family: Gilroy-Regular;
}
.container {
  max-width: 700px;
}
.card {
  /* min-width: 300px; */
  transition: all 0.5s ease;
  display: inline-flex;
  margin-right: 15px;
  margin-bottom: 5px;
  border: 0;
}
.card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
}
.card img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  width: 100%;
  min-height: 100px;
  max-height: 150px;
  filter:brightness(1.1);
}
.card:hover {
  transform: scale(1.035) translate(0, -7px);
  /* transform: translate(0, -7px); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.card-subtitle {
  color: black;
}
.card {
  width: 18rem;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .card {
    width: 100%;
    margin: auto;
  }
}
@media screen and (min-width: 500px) and (max-width: 1000px) {
  .card {
    width: 100%;
    margin: auto;
  }
}
</style>
