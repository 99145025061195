<template>
<div class="container mt-3">
  <div class="container">
    <h1 class="title mb-5">💬 Отзывы об агенте</h1>
    <a role="button" class="btn btn-primary mr-3" rel="noreferrer" target=_blank :href="'https://advecs.com/agents/' + this.id">
      Оставить отзыв 🗗
    </a>
    Всего {{ reviews.length}} отзывов
    <div v-if="loading" class="loader mt-5"></div>
    <div v-if="list_error">{{list_error}}</div>
    <transition-group name="list">
      <div class="mt-3 mb-3 p-3 bg-light" v-for="item in reviews" :key="item">
        <div v-html="item" class="m-3"></div>
      </div>
    </transition-group>
  </div>
</div>
</template>

<script>
export default {
  name: 'ReviewsFetch',
  data() {
    return {
      reviews: '',
      loading: false,
      list_error: '',
    };
  },
  methods: {
    async FetchReviews(id) {
      this.loading = true;
      await new Promise((r) => setTimeout(r, 1200));
      fetch(`https://weather.matveymatvey.com/getreviews/${id}`)
        .then((response) => response.json())
        .then((response) => {
          this.reviews = response;
        })
        .catch((error) => {
          this.list_error = `Ошибка загрузки отзывов - ${error}`;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.FetchReviews(this.id);
  },
  props: ['id'],
};
</script>

<style scoped>
.btn {
  /* border-radius: unset !important; */
}

.loader {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

body {
  background-image: unset;
  background-color: lightgrey;
}

.container {
  max-width: 700px;
}

.card {}

.bl {}

.anons {
  margin-bottom: 15px;
  font-size: 16px;
}

.a,
.date {
  font-style: italic;
  font-size: 14px;
}
</style>
