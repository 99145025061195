<template>
    <div id="app">
      <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'app',
};

</script>

<style>
@import '../scss/custom.scss';

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

</style>
