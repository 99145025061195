<template>
  <div>
    <div class="menubar dropdown dropright dropdown-toggle">
      <ul class="nav icon-menu">
        <!-- icon menu -->
        <li><a href="#/Title"><img src=../assets/SVG/main.svg></a></li>
        <li><a href="#/Contacts"><img src=../assets/SVG/contacts.svg></a></li>
        <li><a href="#/Company"><img src=../assets/SVG/company.svg></a></li>
        <li><a href="#/Services"><img src=../assets/SVG/services.svg></a></li>
        <li><a href="#/Whyus"><img src=../assets/SVG/whyus.svg></a></li>
        <li><a href="#/Buying"><img src=../assets/SVG/buying.svg></a></li>
        <li><a href="#/Selling"><img src=../assets/SVG/selling.svg></a></li>
        <li><a href="#/Partners"><img src=../assets/SVG/partners.svg></a></li>
        <li><a href="#/Novostr"><img src=../assets/SVG/novostr.svg></a></li>
        <li><a href="#/MarketingPlan"><img src=../assets/SVG/marketing.svg></a></li>
        <li><a href="#/Gic"><img src=../assets/SVG/gic.svg></a></li>
      </ul>
      <div class="dropdown-menu text-menu">
        <ul class="nav flex-column">
          <!-- text menu -->
          <li><a href="#/Title">ГЛАВНАЯ</a></li>
          <li><a href="#/Contacts">КОНТАКТЫ АГЕНТА</a></li>
          <li><a href="#/Company">О КОМПАНИИ</a></li>
          <li><a href="#/Services">УСЛУГИ</a></li>
          <li><a href="#/Whyus">ВЫБИРАЙТЕ НАС</a></li>
          <li><a href="#/Buying">ПЛАН ПОКУПКИ</a></li>
          <li><a href="#/Selling">ПЛАН ПРОДАЖИ</a></li>
          <li><a href="#/Partners">ПАРТНЕРЫ</a></li>
          <li><a href="#/Novostr">ПОКУПКА НОВОСТРОЙКИ</a></li>
          <li><a href="#/MarketingPlan">МАРКЕТИНГОВЫЙ ПЛАН</a></li>
          <li><a href="#/Gic">ИПОТЕКА</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
};
</script>

<style scoped>
.dropdown .dropdown-menu {
  border: unset;
  border-radius: unset;
  padding: unset;
}

.dropdown::after {
  border:unset;
}

.menubar {
  margin-top: 30px;
  z-index: 100;
  /* display: inline-block; */
}

.icon-menu {
  width: 50px;
}

.text-menu {
  background-color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  margin-left: 20px;
}

.text-menu a {
  line-height: 40px;
}

/* dropdown:hover */
.dropdown:hover>.dropdown-menu {
  display: block;
}

/* .dropdown>.dropdown-toggle:active {
    pointer-events: none;
} */

li > a > img {
  height: 40px;
  width: 40px;
  -webkit-filter: brightness(100%) opacity(50%) grayscale(100%);
  filter: brightness(100%) opacity(50%) grayscale(100%);
  margin-right: 15px;
  -webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
transition: all 0.3s ease;
}

li.active > a > img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

li:hover > a > img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.reveal .menu ul li a.active, .reveal .menu ul li.active a,
.reveal ul.menu li a.active, .reveal ul.menu li.active a {
text-decoration:underline;
font-family: Gilroy-Medium;
}

.reveal .menu ul li a, .reveal .menu ul li a,
.reveal ul.menu li a, .reveal ul.menu li a {
color: grey;
font-family: Gilroy-Light;
}

</style>
