<template>
  <div class="container-fluid main-container text-center">
      <div class="agent-container mx-auto pt-3">
        <div v-for="(agent, index) in data.agents" :key="index" class="p-3">
          <img :src='agent.photo' class=""><br>
          <span class="font-weight-bold">{{ agent.name }}</span>
        </div>
      </div>
      <div class="text-center mx-auto mt-3 title-container pb-3">
        <h2 class="text-uppercase">Лучшие специалисты компании</h2>
        <h2 class="text-uppercase">{{ data.office }}</h2>
        <h2>по итогам III квотного периода</h2>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: ['office'],
  data() {
    return {
      data: '',
      url: 'https://weather.matveymatvey.com/gettop',
      // url: 'http://127.0.0.1:5000/gettop',
    };
  },
  methods: {
    async Gettop(office) {
      await fetch(`${this.url}/${office}`, {
        method: 'GET',
      })
        .then((response) => {
          if (response.status === 500) {
            this.current_user = 'Ошибка сервера API';
            throw new Error(response.status);
          } else return response.json();
        })
        .then((data) => {
          this.data = data;
        })
        .catch((error) => { console.error('There was en error:', error); });
    },
  },
  mounted() {
    this.Gettop(this.office);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body, html {
  width: 100vw;
  height: 100vh;
}

body {
  /* background: url('../assets/toвp10bg.jpg') !important; */
  color: #fff;
}

.main-container {
  height: 100vh;
}

.agent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
  flex-wrap: wrap;
  max-width: 1150px;
}

/* .agent-container img {
  height: 100px;
  max-height: 100%;
} */

.agent_card {

}

.title-container {
  bottom: 0;
  left: 50%;
}

.wrapper {
  position: absolute;
width:100%;
min-height: 100%;
overflow: hidden;
}
</style>
