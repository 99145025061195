<template>
  <div class="container mt-5" v-if="agent.objects">
    <h1 class="title mb-5">Объекты</h1>

    <div v-if="loading" class="loader mt-5"></div>
    <div v-if="list_error">{{ list_error }}</div>

    <Objectsdeck v-if="agent.objects.new" :obj="agent.objects.new" title="Новостройки" />

    <Objectsdeck v-if="agent.objects.flats" :obj="agent.objects.flats" title="Квартиры" />

    <Objectsdeck v-if="agent.objects.rooms" :obj="agent.objects.rooms" title="Комнаты" />

    <Objectsdeck v-if="agent.objects.country" :obj="agent.objects.country" title="Загородная" />

    <Objectsdeck v-if="agent.objects.rent" :obj="agent.objects.rent" title="Аренда" />

    <Objectsdeck
      v-if="agent.objects.commercial"
      :obj="agent.objects.commercial"
      title="Коммерческая"
    />

    <Objectsdeck v-if="agent.objects.foreign" :obj="agent.objects.foreign" title="Зарубежная" />
  </div>
</template>

<script>
import Objectsdeck from "./Objectsdeck.vue";

export default {
  name: "Objects",
  props: ["agent"],
  components: { Objectsdeck },
  data() {
    return {
      loading: false,
      list_error: '',
      objects: {},
    };
  },
  computed: {
    objectsCount() {
      return this.agent.objects.new;
    }
  }
};
</script>

<style scoped>
body {
  background-image: unset;
  background-color: lightgrey;
  font-family: Gilroy-Medium;
}
.container {
  max-width: 700px;
}
.card {
  min-width: 300px;
}
.card img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  width: 100%;
  min-height: 100px;
  max-height: 150px;
}
.card-subtitle {
  color: black;
}
.card {
  width: 18rem;
}
button .count {
  pointer-events: none !important;
  cursor: none;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .card {
    width: 100%;
  }
}
</style>
